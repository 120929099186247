import axios from "axios";

export const getIngredients = async (userBlob, replaceIngredients) => {
let response;
        const getApiResponse = async () => {
            try {
                response = await axios.get(`/ingredients?userFileName=${userBlob}`);

                if (response.status !== 200) {
                    throw new Error(`Error! status: ${response.status}`);
                  }
                } catch (error) {
                    throw new Error(error.message);
                } finally {
                    replaceIngredients(response.data.ingredients ?? []);
                }
        }

        getApiResponse();
    };