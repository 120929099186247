import React, { useState } from 'react';

import styled from 'styled-components';

import {
  StyledInputWithButton,
} from '../components/GenericStyled/StyledInputWithButton';
import { StyledLabel } from '../components/GenericStyled/StyledLabel';
import { handleKeyPress } from '../helpers/handleKeyPress';

const FormInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const AutocompleteOptions = styled.div`
    position: relative;
    background-color: white;
    padding: 5px;
    width: 150px;
    bottom: 0;

`

export const FormAutocompleteInput = ({onEnter, type, nameKey, options, saveNewOption, displayName}) => {
    const [value, setValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options.sort());
    const [showOptions, setShowOptions] = useState(false);

    const handleChange = (e) => {
        const filtered = options.filter(opt => opt.startsWith(e.target.value));
        setFilteredOptions(filtered);
        setShowOptions(true);
        setValue(e.target.value);
    }

    const handleEnter = async () => {
        if(!options.includes(value.toLowerCase())){
            await saveNewOption(value.toLowerCase());
        };
        onEnter(value.toLowerCase());
        setValue('');
        setShowOptions(false);
    }

    const handleTab = () => {
        setShowOptions(true);
        setValue(filteredOptions[0]);
    }

    return (
        <FormInputWrapper>
            <StyledLabel for={nameKey}>{displayName}</StyledLabel>
            <StyledInputWithButton
                type={type}
                name={nameKey}
                value={value}
                onChange={handleChange}
                onKeyDown={(e) => {handleKeyPress(e, handleEnter, handleTab)}}
                autocomplete="off"
            />
            {showOptions && options.length > 0 && <AutocompleteOptions>
                {filteredOptions.map(x => <ul>{x}</ul>)}
                </AutocompleteOptions>}
        </FormInputWrapper>
    )
}