import React, { useState } from 'react'

import styled from 'styled-components'

import { colorPalette } from '../../assets/colorPalette'
import { dashboardModes } from '../../constants/dashboardModes'
import { ToggleButton } from '../Generic/ToggleButton'
import { Recipes } from '../Recipes/Recipes'
import { ShoppingLists } from '../ShoppingLists/ShoppingLists'

const LeftBarStyled = styled.div`
    display: flex;
    flex: 1 0 300px;
    flex-direction: column;
    border: ridge 5px ${colorPalette.mahogany_dd};
    border-radius: 5px;
    margin: 5px;
    box-sizing: border-box;
    background-color: ${colorPalette.bamboo_dd};
    padding: 10px 5px;
    align-items: center;
    max-width: 300px;
    max-height: 95vh;
`

const ModeToggleStyled = styled.div`
    display: flex;
    width: 100%;
`
const ModeButton = styled(ToggleButton)`
    font-size: 0.8em;
    flex 1 0 auto;
`

export const LeftBar = ({
    dashboardMode,
    setDashboardMode,
    setViewPaneData,
    canMealPlan,
}) => {
    const [mode, setMode] = useState('recipes')

   return (
    <LeftBarStyled>
        <ModeToggleStyled>
            <ModeButton toggled={mode==='recipes'} onClick={() => setMode('recipes')}>Menu</ModeButton>
            <ModeButton toggled={mode==='shopping-lists'} onClick={() => setMode('shopping-lists')}>Shopping Lists</ModeButton>
        </ModeToggleStyled>
        {mode === 'recipes' && <Recipes setDashboardMode={setDashboardMode} setViewPaneData={setViewPaneData} canMealPlan={canMealPlan} isCreatingShoppingList={dashboardMode === dashboardModes.GENERATE_SHOPPING_LIST} showShoppingList={() => setDashboardMode(dashboardModes.GENERATE_SHOPPING_LIST)}/>}
        {mode === 'shopping-lists' && <ShoppingLists dashboardMode={dashboardMode} addNewShoppingList={() => setDashboardMode(dashboardModes.SHOPPING_LIST_FORM)} combineShoppingLists={() => setDashboardMode(dashboardModes.COMBINE_SHOPPING_LISTS)} />}
    </LeftBarStyled>
    )
}
