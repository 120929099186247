import React from 'react'

import styled from 'styled-components'

const ListStyled = styled.div`
    flex-grow: 2;
    overflow: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`

const FooterStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
`;

export const SidebarContent = ({title, children, buttonList}) => (
<>
    <h1>{title}</h1>
    <ListStyled>
        {children}
    </ListStyled>
    <FooterStyled>
        {buttonList}
    </FooterStyled>
</>
);