import styled, { css } from 'styled-components'
import { colorPalette } from '../../assets/colorPalette'
import { fonts } from '../../assets/fonts'

export const StyledButton = styled.button`
    font-family: ${fonts.jost};
    padding: 7px 14px;
    background-color: ${colorPalette.bamboo};
    font-size: 1em;
    border: outset 1px ${colorPalette.bamboo};
    border-radius: 5px;
    box-shadow: 1px 1px 2px 0 ${colorPalette.mahogany}, -0.5px -0.5px 1px ${colorPalette.milk_chocolate};

    &:active {
        background-color: ${colorPalette.mahogany};
        border: inset 1px ${colorPalette.bamboo};
    }

    ${(props) => props.mode === 'primary' && css`
        background-color: ${colorPalette.charcoal};
        color: ${colorPalette.bamboo};
    `}

    ${(props) => props.mode === 'secondary' && css`
        background-color: inherit;
        color: ${colorPalette.charcoal};
        border-color: ${colorPalette.charcoal};
    `}

`