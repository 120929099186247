import React, {
    useEffect,
    useState,
} from 'react'

import styled from 'styled-components'

import { colorPalette } from '../../assets/colorPalette'
import { StyledButton } from '../GenericStyled/StyledButton'

const StyledSelectedButton = styled(StyledButton)`
    background-color: ${colorPalette.mahogany};
    color: white;
`

export const ToggleButton = ({ onClick, toggled = false, children }) => {
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        setIsSelected(toggled)
    }, [toggled])

    const toggleButton = () => {
        if (!toggled) {
            setIsSelected(!isSelected)
        }

        onClick()
    }

    return isSelected ? (
        <StyledSelectedButton onClick={toggleButton}>
            {children}
        </StyledSelectedButton>
    ) : (
        <StyledButton onClick={toggleButton}>{children}</StyledButton>
    )
}
