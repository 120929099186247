import { getGroceryDepartments } from "./getGroceryDepartments";
import { getIngredients } from "./getIngredients";
import { getMealPlans } from "./getMealPlans"
import { getRecipes } from "./getRecipes";
import { getShoppingLists } from "./getShoppingLists";

export const getInitialData = async (userBlob, updateStateHandlers) => {
    const {masterGroceryListHandlers, mealPlanHandlers, recipeHandlers, shoppingListHandlers, ingredientsHandlers} = updateStateHandlers;
    
    await getGroceryDepartments(masterGroceryListHandlers.updateGroceryDepartments);
    await getMealPlans(userBlob, mealPlanHandlers.updateMealPlan);
    await getRecipes(userBlob, recipeHandlers.replaceRecipes);
    await getShoppingLists(userBlob, shoppingListHandlers.replaceShoppingLists);
    await getIngredients(userBlob, ingredientsHandlers.replaceIngredients);
};
