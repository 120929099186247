import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { getInitialData } from '../api/getInitialData'
import { dashboardModes } from '../constants/dashboardModes'
import { mealPlanModes } from '../constants/mealPlanModes'
import { PaperPlateContext } from '../store/PaperPlateProvider'
import { Header } from './Header/Header'
import { LeftBar } from './LeftBar/LeftBar'
import { MasterGroceryList } from './MasterGroceries/MasterGroceryList'
import { ViewPane } from './ViewPane'

const DashboardStyled = styled.div`
    display: flex;
    flex-direction: row;
    background-image: url('backgroundImage.jpg');
    background-size: cover;
    height: 100vh;
`

export const Dashboard = () => {
    const [mode, setMode] = useState(dashboardModes.DEFAULT)
    const [mealPlanMode, setMealPlanMode] = useState(mealPlanModes.VIEW)
    const [viewPaneData, setViewPaneData] = useState({})
    const {
        user,
        userHandlers,
        masterGroceryListHandlers,
        shoppingListHandlers,
        recipeHandlers,
        mealPlanHandlers,
    } = useContext(PaperPlateContext)

    const { isEditingMasterGroceries, setIsEditingMasterGroceries } =
        useState(false)

    const handlers = {
        masterGroceryListHandlers,
        shoppingListHandlers,
        recipeHandlers,
        mealPlanHandlers,
    }

    useEffect(() => {
        getInitialData(user.userBlob, handlers)
    }, [user])

    return (
        !!user.userBlob && (
            <>
                <Header
                    logout={() => userHandlers.updateUser({})}
                    editMasterGroceries={() =>
                        setIsEditingMasterGroceries(true)
                    }
                />
                {isEditingMasterGroceries && <MasterGroceryList />}
                {!isEditingMasterGroceries && (
                    <DashboardStyled>
                        <LeftBar
                            dashboardMode={mode}
                            setDashboardMode={setMode}
                            canMealPlan={
                                mealPlanMode === mealPlanModes.PLANNING
                            }
                            setViewPaneData={setViewPaneData}
                        />
                        <ViewPane
                            mode={mode}
                            setMode={setMode}
                            mealPlanMode={mealPlanMode}
                            setMealPlanMode={setMealPlanMode}
                            data={viewPaneData}
                        />
                    </DashboardStyled>
                )}
            </>
        )
    )
}
