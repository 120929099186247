import React, { useContext, useState } from 'react'

import styled from 'styled-components'
import { FormAutocompleteInput } from '../../FormComponents/FormAutocompleteInput'
import { saveData } from '../../helpers/api/saveData'
import { PaperPlateContext } from '../../store/PaperPlateProvider'

import { Item } from '../Generic/Item'
import { SecondaryButtonStyled } from '../GenericStyled/StyledSecondaryButton'

// const IngredientsListBuilderStyled = styled.div`
//     margin: ${s.pageMargin}
// `
const IngredientsListStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    border: 2px black solid;
    border-radius: 10px;
    padding: 10px;
    max-height: 200px;
    overflow: scroll;

    ::-webkit-scrollbar {
        width: 0;
    }
`

export const IngredientsListBuilder = ({recipeIngredients, setRecipeIngredients}) => {
    const [removeOnUpdate, setRemoveOnUpdate] = useState([]);
    const {
        user,
        ingredients: ingredientOptions,
        ingredientsHandlers
    } = useContext(PaperPlateContext)

    const saveIngredient = async (newIngredient) => saveData("/ingredients", {userBlob: user.userBlob, ingredients: [...ingredientOptions, newIngredient]}, () => ingredientsHandlers.replaceIngredients([...ingredientOptions, newIngredient]))

    const onEnterRecipeIngredient = (input) => {
        const updated = [...recipeIngredients, input];
        setRecipeIngredients(updated);
    }

    const trackRemoveIngredient = (ingredient) => {
        if (removeOnUpdate.includes(ingredient)) {
            const updated = removeOnUpdate.filter((x) => x === ingredient);
            setRemoveOnUpdate(updated);
        } else {
            setRemoveOnUpdate([...removeOnUpdate, ingredient]);
        }
    }

    const updateToRemove = () => {
        let updated = recipeIngredients;

        removeOnUpdate.forEach((removed) => {
            updated = updated.filter((ingredient) => ingredient !== removed);
        });

        setRecipeIngredients(updated);
        setRemoveOnUpdate([]);
    }

    

    return (
        <div>
            {/* <FormInput nameKey="ingredient" displayName="Enter Ingredient" type="text" onEnter={onEnterIngredient} /> */}
            <div>
            <FormAutocompleteInput nameKey="ingredient" displayName="Enter Ingredient" type="text" onEnter={onEnterRecipeIngredient} options={ingredientOptions} saveNewOption={saveIngredient}/>
            </div>
            {recipeIngredients.length > 0 &&
                <>
                    <IngredientsListStyled>
                        {recipeIngredients.map(ingredient =>
                            <Item
                                key={ingredient}
                                value={ingredient}
                                trackRemoveItem={trackRemoveIngredient}
                            />
                        )}
                    </IngredientsListStyled>
                    <SecondaryButtonStyled onClick={updateToRemove}>Update</SecondaryButtonStyled>
                </>
            }
        </div>
    )
}