import React from 'react'
import { dashboardModes } from '../constants/dashboardModes'
import { RecipeForm } from './Recipes/RecipeForm'
import { MealPlan } from './MealPlan/MealPlan';
import { ShoppingListCombiner } from './ShoppingLists/ShoppingListCombiner'
import { ShoppingListForm } from './ShoppingLists/ShoppingListForm'
import { ShoppingListGenerator } from './ShoppingLists/ShoppingListGenerator'

export const ViewPane = ({
    mode,
    setMode,
    mealPlanMode,
    setMealPlanMode,
    data,
}) => {
    const closeView = () => setMode(dashboardModes.DEFAULT)

    return (
        <>
            {mode === dashboardModes.RECIPE_FORM && (
                <RecipeForm closeForm={closeView} />
            )}
            {mode === dashboardModes.VIEW_RECIPE && (
                <RecipeForm closeForm={closeView} recipe={data} />
            )}
            {mode === dashboardModes.GENERATE_SHOPPING_LIST && (
                <ShoppingListGenerator closeView={closeView} />
            )}
            {mode === dashboardModes.SHOPPING_LIST_FORM && (
                <ShoppingListForm closeForm={closeView} />
            )}
            {mode === dashboardModes.COMBINE_SHOPPING_LISTS && (
                <ShoppingListCombiner closeView={closeView} />
            )}
            {mode === dashboardModes.DEFAULT && (
                <MealPlan 
                    mealPlanMode={mealPlanMode}
                    setMealPlanMode={setMealPlanMode}
                />
            )}
        </>
    )
}
