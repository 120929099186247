import React, {
    useContext,
    useRef,
    useState,
} from 'react'

import axios from 'axios'
import { useReactToPrint } from 'react-to-print'
import styled, { css } from 'styled-components'

import { colorPalette } from '../../assets/colorPalette'
import { mealPlanModes } from '../../constants/mealPlanModes'
import { saveData } from '../../helpers/api/saveData'
import {
    generateShoppingListFromRecipes,
} from '../../helpers/generateShoppingListFromRecipes'
import { PaperPlateContext } from '../../store/PaperPlateProvider'
import { ToggleButton } from '../Generic/ToggleButton'
import { StyledButton } from '../GenericStyled/StyledButton'
import { MealTile } from './MealTile'

const MealPlanModeToggleStyled = styled.div`
    display: flex;
    font-size: 0.8em;
`

const MealPlanViewStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
    gap: 10px;
    height: 95vh;
`

const MealPlanGridStyled = styled.div`
    display: grid;
    flex-grow: 2;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(7, 1fr);
    background-color: ${colorPalette.bamboo_dd};
    grid-auto-flow: row;
    grid-gap: 10px;
    padding: 20px;
    border-radius: 10px;
    border: ridge 3px ${colorPalette.mahogany};
`

const MealPlanFooterStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    ${(props) =>
        props.selected &&
        css`
            justify-content: space-between;
        `}
`

export const MealPlan = ({ mealPlanMode, setMealPlanMode }) => {
    const {
        user,
        mealPlan,
        mealPlanHandlers,
        shoppingLists,
        shoppingListHandlers,
    } = useContext(PaperPlateContext)
    const [selectedForShopping, setSelectedForShopping] = useState([])

    const onSaveSuccess = () => mealPlanHandlers.updateMealPlan(mealPlan);

    const saveMealPlan = () => saveData(
        '/mealPlans', 
        {mealPlan, userBlob: user.userBlob},
        onSaveSuccess
        )

    const generateShoppingListFromSelected = () => {
        const today = new Date()
        const month = today.getMonth() + 1
        const day = today.getDate()

        const selectedRecipes = selectedForShopping
            .map((selectedIndex) => mealPlan[selectedIndex])
            .filter(
                (selectedRecipe) =>
                    selectedRecipe && Object.keys(selectedRecipe).length > 0
            )

        const items = generateShoppingListFromRecipes(selectedRecipes)

        const generatedShoppingList = {
            shoppingListName: `Meal Plan ${month}/${day}`,
            items,
        }
        return generatedShoppingList
    }

    const saveGeneratedShoppingList = async (list) => {
        try {
            const response = await axios.post('/shoppingLists', {
                data: {
                    shoppingLists: [...shoppingLists, list],
                    userBlob: user.userBlob,
                },
            })

            if (response.status !== 200) {
                throw new Error(response.message)
            }
        } catch (err) {
            console.log(err.message)
        } finally {
            shoppingListHandlers.addShoppingList(list)
        }
    }

    const createShoppingList = async () => {
        const generatedList = generateShoppingListFromSelected()
        saveGeneratedShoppingList(generatedList)
    }

    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
            border: none;

            @page {
                margin: 0 50mm;
            }
        `,
    })

    return (
        <MealPlanViewStyled>
            <MealPlanModeToggleStyled>
                <ToggleButton
                    toggled={mealPlanMode === mealPlanModes.VIEW}
                    onClick={() => setMealPlanMode(mealPlanModes.VIEW)}
                >
                    View
                </ToggleButton>
                <ToggleButton
                    toggled={mealPlanMode === mealPlanModes.PLANNING}
                    onClick={() => setMealPlanMode(mealPlanModes.PLANNING)}
                >
                    Planning
                </ToggleButton>
                <ToggleButton
                    toggled={mealPlanMode === mealPlanModes.SHOPPING}
                    onClick={() => setMealPlanMode(mealPlanModes.SHOPPING)}
                >
                    Shopping
                </ToggleButton>
            </MealPlanModeToggleStyled>

            <MealPlanGridStyled ref={printRef}>
                {mealPlan.map((m, i) => (
                    <MealTile
                        key={`${m.name} - ${Math.random()}`}
                        dayMealPlan={m}
                        position={i}
                        canSelectForShopping={
                            mealPlanMode === mealPlanModes.SHOPPING
                        }
                        selectedForShopping={selectedForShopping}
                        setSelectedForShopping={setSelectedForShopping}
                    />
                ))}
            </MealPlanGridStyled>

            <MealPlanFooterStyled selected={selectedForShopping.length >= 1}>
                {selectedForShopping.length >= 1 && (
                    <StyledButton onClick={() => setSelectedForShopping([])}>
                        Clear Selection
                    </StyledButton>
                )}
                <div>
                    {selectedForShopping.length >= 1 && (
                        <StyledButton onClick={createShoppingList}>
                            Create Shopping List
                        </StyledButton>
                    )}
                    {selectedForShopping.length === 0 && (
                        <StyledButton type="button" onClick={handlePrint}>
                            Print
                        </StyledButton>
                    )}
                    <StyledButton onClick={saveMealPlan}>
                        Save Meal Plan
                    </StyledButton>
                </div>
            </MealPlanFooterStyled>
        </MealPlanViewStyled>
    )
}
