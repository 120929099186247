import React, { useContext } from 'react'

import { dashboardModes } from '../../constants/dashboardModes'
import { PaperPlateContext } from '../../store/PaperPlateProvider'
import { SidebarContent } from '../Generic/SidebarContent'
import { StyledButton } from '../GenericStyled/StyledButton'
import { RecipeButton } from './RecipeButton'

export const Recipes = ({setDashboardMode, setViewPaneData, isCreatingShoppingList, showShoppingList, canMealPlan}) => {
    const {recipes, shoppingListHandlers } = useContext(PaperPlateContext);

    const onRecipeClick = (recipe) => {
        if(isCreatingShoppingList) {
            shoppingListHandlers.addRecipeToShoppingList(recipe);
        } else {
            setViewPaneData(recipe);
            setDashboardMode(dashboardModes.VIEW_RECIPE)
        }
    };

    const sortRecipes = (a, b) => {
        if (a.recipeName.toUpperCase() < b.recipeName.toUpperCase()) {
            return -1;
        }

        if (a.recipeName.toUpperCase() > b.recipeName.toUpperCase()) {
            return 1;
        }

        return 0;
    }

    const footerButtons = [
            <StyledButton type='button' onClick={() => setDashboardMode(dashboardModes.RECIPE_FORM)} setViewPaneData={setViewPaneData}>Add New Recipe</StyledButton>,
            <StyledButton type='button' onClick={showShoppingList}>Create Shopping List</StyledButton>
    ];

    return (
        <SidebarContent title='Menu' buttonList={footerButtons}>
            {recipes.sort(sortRecipes).map((recipe) => 
                    <RecipeButton key={`${recipe.recipeName}`} onClick={onRecipeClick} recipe={recipe} unselectAll={!isCreatingShoppingList} canMealPlan={canMealPlan} withDelete />
                )}
        </SidebarContent>
    )
};