import axios from "axios";

export const getGroceryDepartments = (updateGroceryDepartments) => {
    let response;
    const getApiResponse = async () => {
        try {
            response = await axios.get('/groceryDepartments');

            if (response.status !== 200) {
                throw new Error(`Error! status: ${response.status}`);
              }
            } catch (error) {
                throw new Error(error.message);
            } finally {
                updateGroceryDepartments(response.data.groceryDepartments ?? []);
            }
    }

    getApiResponse();
};