import React, { useState } from 'react'

import styled from 'styled-components'

import { FormInput } from '../../FormComponents/FormInput'
import { Item } from '../Generic/Item'
import { SecondaryButtonStyled } from '../GenericStyled/StyledSecondaryButton'

// const IngredientsListBuilderStyled = styled.div`
//     margin: ${s.pageMargin}
// `

const ItemsListStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    gap: 10px;
    border: 2px black solid;
    border-radius: 10px;
    padding: 10px;
    max-height: 400px;
    overflow: scroll;

    ::-webkit-scrollbar {
        width: 0;
    }
`

export const ItemsListBuilder = ({items, setItems }) => {
    const [removeOnUpdate, setRemoveOnUpdate] = useState([]);

    const onEnterItem = (input) => {
        const updated = [...items, input];
        setItems(updated);
    }

    const trackRemoveItem = (ingredient) => {
        if (removeOnUpdate.includes(ingredient)) {
            const updated = removeOnUpdate.filter((x) => x === ingredient);
            setRemoveOnUpdate(updated);
        } else {
            setRemoveOnUpdate([...removeOnUpdate, ingredient]);
        }
    }

    const updateToRemove = () => {
        let updated = items;

        removeOnUpdate.forEach((removed) => {
            updated = updated.filter((ingredient) => ingredient !== removed);
        });

        setItems(updated);
        setRemoveOnUpdate([]);
    }

    return (
        <div>
            <FormInput nameKey="item" displayName="Enter Item" type="text" onEnter={onEnterItem} />
            {items.length > 0 &&
                <>
                    <ItemsListStyled>
                        {items.map(item =>
                            <Item
                                key={item}
                                value={item}
                                trackRemoveItem={trackRemoveItem}
                            />
                        )}
                    </ItemsListStyled>
                    <SecondaryButtonStyled onClick={updateToRemove}>Update</SecondaryButtonStyled>
                </>
            }
        </div>
    )
}