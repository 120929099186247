import React, { useMemo, useReducer, createContext } from 'react';
import { useDraggable } from '../hooks/useDraggable';
import { ingredientsReducer } from './ingredientsReducer';
import { masterGroceryListReducer } from './masterGroceryListReducer';
import { mealPlanReducer } from './mealPlanReducer';
import { recipeReducer } from './recipeReducer';
import { shoppingListReducer } from './shoppingListReducer';
import { ingredientsActions, initialState, masterGroceryListActions, mealPlanActions, recipeActions, shoppingListActions, userActions } from './store';
import { userReducer } from './userReducer';

export const PaperPlateContext = createContext();

export const PaperPlateProvider = ({ children }) => {
    const [userState, userDispatch] = useReducer(userReducer, initialState);
    const [masterGroceryListState, masterGroceryListDispatch] = useReducer(masterGroceryListReducer, initialState);
    const [recipeState, recipeDispatch] = useReducer(recipeReducer, initialState);
    const [shoppingListState, shoppingListDispatch] = useReducer(shoppingListReducer, initialState);
    const [mealPlanState, mealPlanDispatch] = useReducer(mealPlanReducer, initialState);
    const [ingredientsState, ingredientsDispatch] = useReducer(ingredientsReducer, initialState);
    const {dragRef, dragHandlers} = useDraggable();
    const userHandlers = {
        updateUser: (userData) => {
            userDispatch({ type: userActions.UPDATE_USER, userData})
        },
    };

    const masterGroceryListHandlers = {
        updateGroceryDepartments: (departments) => {
            masterGroceryListDispatch({ type: masterGroceryListActions.UPDATE_GROCERY_DEPARTMENTS, departments})
        }
    };

    const recipeHandlers = {
        addRecipe: (recipe) => {
            recipeDispatch({type: recipeActions.ADD_RECIPE, recipe})
        },

        deleteRecipe: (recipeId) => {
            recipeDispatch({ type: recipeActions.DELETE_RECIPE, recipeId})
        },

        replaceRecipes: (recipes) => {
            recipeDispatch({ type: recipeActions.REPLACE_RECIPES, recipes})
        }
    }

    const shoppingListHandlers = {
        addShoppingList: (shoppingList) => {
            shoppingListDispatch( {type: shoppingListActions.ADD_SHOPPING_LIST, shoppingList})
        },

        replaceShoppingLists: (shoppingLists) => {
            shoppingListDispatch({ type: shoppingListActions.REPLACE_SHOPPING_LISTS, shoppingLists})
        },

        addRecipeToShoppingList: (recipe) => {
            shoppingListDispatch({ type: shoppingListActions.ADD_RECIPE_TO_SHOPPING_LIST, recipe })
        },

        addShoppingListToCombine: (shoppingList) => {
            shoppingListDispatch({ type: shoppingListActions.ADD_SHOPPING_LIST_TO_COMBINE, shoppingList})
        },

        clearRecipesForList: () => {
            shoppingListDispatch({ type: shoppingListActions.CLEAR_RECIPES_FOR_LIST })
        },

        clearListsToCombine: () => {
            shoppingListDispatch({ type: shoppingListActions.CLEAR_LISTS_TO_COMBINE})
        },
    };

    const mealPlanHandlers = {
        updateMealPlan: (mealPlan) => {
            mealPlanDispatch({ type: mealPlanActions.UPDATE_MEAL_PLAN, mealPlan })
        }
    }

    const ingredientsHandlers = {
        addIngredient: (ingredient => {
            ingredientsDispatch({ type: ingredientsActions.ADD_INGREDIENT, ingredient});
        }),
        replaceIngredients: (ingredients => {
            ingredientsDispatch({ type: ingredientsActions.REPLACE_INGREDIENTS, ingredients})
        })
    }

    const value = useMemo(() => ({
        user: userState.user,
        recipes: recipeState.recipes,
        shoppingLists: shoppingListState.shoppingLists,
        shoppingListRecipes: shoppingListState.shoppingListRecipes,
        shoppingListsToCombine: shoppingListState.shoppingListsToCombine,
        mealPlan: mealPlanState.mealPlan,
        groceryDepartments: masterGroceryListState.groceryDepartments,
        ingredients: ingredientsState.ingredients,
        userHandlers,
        masterGroceryListHandlers,
        recipeHandlers,
        shoppingListHandlers,
        mealPlanHandlers,
        ingredientsHandlers,
        dragRef,
        dragHandlers,
    }), [recipeState, shoppingListState, mealPlanState, ingredientsState, userHandlers, recipeHandlers, shoppingListHandlers, mealPlanHandlers, ingredientsHandlers, dragRef, dragHandlers]);

    return (
        <PaperPlateContext.Provider value={value}>
            {children}
        </PaperPlateContext.Provider>
    )
}

export default PaperPlateProvider;