import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colorPalette } from '../../assets/colorPalette';
import { fonts } from '../../assets/fonts';
import { StyledButton } from '../GenericStyled/StyledButton';

const StyledHeader = styled.div`
    width: 100vw;
    background-color: ${colorPalette.rainforest_cc};
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    box-sizing: border-box;
    position: absolute;
    z-index: 9;
`;

const StyledCollapsedHeader = styled.div`
    width: 100vw;
    background-color: ${colorPalette.rainforest_cc};
    color: ${colorPalette.ash};
    text-align: center;
    font-family: ${fonts.button};

    ${props => !props.show && css`
    visibility: hidden;
    `}
`;

export const Header = ({logout, editMasterGroceries}) => {
    const [show, setShow] = useState(false);
    return (
        <>
        <StyledCollapsedHeader show={!show} onMouseOver={() => setShow(true)}>View Menu</StyledCollapsedHeader>
        {show && <StyledHeader onMouseLeave={() => setShow(false)}>
            <StyledButton onClick={editMasterGroceries}>Edit Master Grocery List</StyledButton>
            <StyledButton onClick={logout}>Logout</StyledButton>
        </StyledHeader>}
        </>
    )};