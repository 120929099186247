import React, { useState, useContext } from 'react';

import styled, { css } from 'styled-components';
import { colorPalette } from '../../assets/colorPalette';
import { fonts } from '../../assets/fonts';
import { PaperPlateContext } from '../../store/PaperPlateProvider';

const MealTileStyled = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 30%;
    border: ridge 3px ${colorPalette.mahogany};
    border-radius: 10px;
    background-color: ${colorPalette.cardstock};
    padding: 2px;
    font-family: ${fonts.jost};
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: ${colorPalette.cucumber};
        font-size: 1.1rem;
    }

    ${props => props.selected && css`
        background-color: ${colorPalette.cucumber};
    `}
`;

export const MealTile = ({dayMealPlan, position, canSelectForShopping, selectedForShopping, setSelectedForShopping}) => {
    const [hover, setHover] = useState(false);
    const {dragHandlers} = useContext(PaperPlateContext);
    const {onDragEnter: onDragEnterForPlanning, onDragLeave} = dragHandlers;

    const onAddForShopping = () => {
        if(!selectedForShopping.includes(position)) {
            setSelectedForShopping([...selectedForShopping, position]);
        };
    }

    const handleDragEnter = () => {
        if(!canSelectForShopping) {
            onDragEnterForPlanning(position);
        } else {
            onAddForShopping();
        }
    }

    const handleDragLeave = () => {
        if(!canSelectForShopping) {
            onDragLeave();
        }
    }

    return (
        <MealTileStyled 
            hover={hover} 
            draggable={canSelectForShopping} 
            selected={selectedForShopping.includes(position)}
            onClick={onAddForShopping}
            onDragEnter={handleDragEnter} 
            onDragLeave={handleDragLeave} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
        >
            <span>{dayMealPlan.recipeName || 'Empty'}</span>
        </MealTileStyled>
    )
}