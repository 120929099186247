import {
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useRecipeFormState = (recipe = {}) => {
  const [recipeName, setRecipeName] = useState(recipe?.recipeName ?? '');
  const [rating, setRating] = useState(recipe?.rating ?? '');
  const [recipeIngredients, setRecipeIngredients] = useState(recipe?.recipeIngredients ?? []);
  const [instructions, setInstructions] = useState(recipe?.instructions ?? []);
  const [formData, setFormData] = useState({});

  const recipeId = uuidv4();

  useEffect(() => {
    const updatedData = {
      recipeId,
      recipeName,
      rating,
      recipeIngredients,
      instructions,
    };

    setFormData(updatedData);
  }, [recipeName, rating, recipeIngredients, instructions]);

  const clearForm = () => {
    setRecipeName("");
    setRating("");
    setRecipeIngredients([]);
    setInstructions([]);
    setFormData({});
  };

  return {
    recipeName,
    setRecipeName,
    rating,
    setRating,
    recipeIngredients,
    setRecipeIngredients,
    instructions,
    setInstructions,
    formData,
    clearForm,
  };
};
