import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../assets/colorPalette';
import { FormInputControlled } from '../../FormComponents/FormInputControlled';
import { saveData } from '../../helpers/api/saveData';
import { PaperPlateContext } from '../../store/PaperPlateProvider';
import { StyledButton } from '../GenericStyled/StyledButton';

const LoginContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${colorPalette.bamboo_dd};
  justify-content: center;
  align-items: center;
  padding: 50px;
  border: solid 2px ${colorPalette.mahogany};
  border-radius: 10px;
  margin: 300px;
`;

const LoginButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`

export const Login = () => {
    const {user, userHandlers} = useContext(PaperPlateContext)
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const IS_FIRST_TIME = {
        true: true,
        false: false,
    };

      const authorizeUser = (isFirstTime) => {
        const authInfo = {
            username,
            password
        };
        const endpoint = isFirstTime ? '/userData/createUser' : '/userData';
        saveData(endpoint, authInfo, (res) => userHandlers.updateUser(res));
        setUsername('');
        setPassword('');
      }

    return !user.userBlob && 
        <LoginContainerStyled>
            <FormInputControlled type="text" displayName='Username' value={username} setValue={setUsername} />
            <FormInputControlled type="password" displayName='Password' value={password} setValue={setPassword} />
            <LoginButtonContainer>
              <StyledButton onClick={() => authorizeUser(IS_FIRST_TIME.true)}>Create User</StyledButton>
              <StyledButton onClick={() => authorizeUser(IS_FIRST_TIME.false)}>Login</StyledButton>
            </LoginButtonContainer>
        </LoginContainerStyled>
};