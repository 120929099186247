import React, { useContext } from 'react'

import styled from 'styled-components'

import { colorPalette } from '../../assets/colorPalette'
import s from '../../assets/styles'
import { FormInputControlled } from '../../FormComponents/FormInputControlled'
import { saveData } from '../../helpers/api/saveData'
import { useRecipeFormState } from '../../hooks/useRecipeFormState'
import { PaperPlateContext } from '../../store/PaperPlateProvider'
import { StyledButton } from '../GenericStyled/StyledButton'
import { IngredientsListBuilder } from './IngredientsListBuilder'
import { InstructionsListBuilder } from './InstructionsListBuilder'

const RecipeFormStyled = styled.div`
    margin: ${s.pageMargin};
    background-color: ${colorPalette.bamboo};
    max-height: 75vh;
    max-width: 60%;
    border: ridge 4px ${colorPalette.mahogany};
    border-radius: 10px;
    padding: 22px 20px;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    ::-webkit-scrollbar {
        width: 0;
    }
`

const FormBodyStyled = styled.div`
    flex-grow: 1;
`

const FormFooterStyled = styled.div`
    align-self: flex-end;
`

export const RecipeForm = ({closeForm, recipe = {}}) => {
    const {user, recipes, recipeHandlers} = useContext(PaperPlateContext)
    const formState = useRecipeFormState(recipe);

    const {addRecipe} = recipeHandlers
    
    const onSaveSuccess = () => {
        addRecipe(formState.formData);
        formState.clearForm();
        closeForm();
    }

    const saveRecipe = () => saveData(
        '/recipes', 
        {recipes: [...recipes, formState.formData], userBlob: user.userBlob},
        onSaveSuccess
        )

    return (
        <RecipeFormStyled>
            <FormBodyStyled>
                <FormInputControlled
                    nameKey="recipeName"
                    displayName="Recipe Name"
                    type="text"
                    value={formState.recipeName}
                    setValue={formState.setRecipeName}
                />
                <FormInputControlled
                    nameKey="rating"
                    displayName="Rating"
                    type="text"
                    value={formState.rating}
                    setValue={formState.setRating}
                />
                <IngredientsListBuilder
                    recipeIngredients={formState.recipeIngredients}
                    setRecipeIngredients={formState.setRecipeIngredients}
                />
                <InstructionsListBuilder
                    instructions={formState.instructions}
                    setInstructions={formState.setInstructions}
                />
            </FormBodyStyled>

            <FormFooterStyled>
                <StyledButton type="button" onClick={saveRecipe} mode='primary'>Save Recipe</StyledButton>
                <StyledButton type="button" onClick={closeForm}>X</StyledButton>
            </FormFooterStyled>
        </RecipeFormStyled>
    )
}
