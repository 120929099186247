import { ingredientsActions } from "./store";

export const ingredientsReducer = (state, action) => {
    switch (action.type) {
      case ingredientsActions.ADD_INGREDIENT:
        return {
            ...state,
            ingredients: [
                ...state.ingredients,
                action.ingredient
            ]
        }

    case ingredientsActions.REPLACE_INGREDIENTS:
        return {
            ...state,
            ingredients: action.ingredients
        }

      default:
        return state;
    }
  }