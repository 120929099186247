import { masterGroceryListActions } from "./store";

export const masterGroceryListReducer = (state, action) => {
    switch (action.type) {
      case masterGroceryListActions.UPDATE_GROCERY_DEPARTMENTS: {
        return {
            ...state,
            groceryDepartments: action.groceryDepartments,
        };
        }
      default:
        return state;
    }
  }